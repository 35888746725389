import { replaceHtml } from '../utils/util';
import { modelHTML } from './constant';
import { selectHightlightShow } from './select';
import tooltip from '../global/tooltip';
import editor from '../global/editor';
import { setcellvalue } from '../global/setdata';
import { getcellvalue } from '../global/getdata';
import { jfrefreshgrid } from '../global/refresh';
import Store from '../store';
import locale from '../locale/locale';
import { setting_luckysheetdata_tablename_url, setting_luckysheetdata_write_url } from '../utils/setting'

//上传数据
const luckysheetUploadData = {
    createDialog: function(){
        let _this = this;

        const _locale = locale();
        const locale_uploadData = _locale.uploadData;
        const locale_punctuation = _locale.punctuation;
        const locale_button = _locale.button;

        $("#luckysheet-modal-dialog-mask").show();
        $("#luckysheet-uploadData-dialog").remove();

        let content = '<div class="box">' +
                        '<div class="boxTitle">'+locale_uploadData.uploadTableName+'</div>' +
                        '<input class="formulaInputFocus data-verification-value1" id="splitColumn_type_01" placeholder="'+locale_uploadData.placeholder1+'" spellcheck="false"></input>' +
                        '<div class="boxTitle" style="margin-top: 10px;">'+ locale_uploadData.uploadCloudDataPreview +'</div>' +
                        '<div class="boxMain" id="uploadCloudColumnData"></div>' +
                        '<div style="height: 22px;line-height: 22px;">' +
                        '<input id="uploadColumn_type_06" type="checkbox"/>' +
                        '<label for="uploadColumn_type_06">'+ locale_uploadData.uploadSymbol +'</label>' +
                        '</div>' +
                        '<div class="boxTitle" style="margin-top: 10px;">'+ locale_uploadData.uploadDataPreview +'</div>' +
                        '<div class="boxMain" id="splitColumnData"></div>' +
                      '</div>';
                    // content – "分割符号" – "Tab 键" – "分号" – "逗号" – "空格" – "其它" – "连续分隔符号视为单个处理" – "数据预览"
        $("body").first().append(replaceHtml(modelHTML, { 
            "id": "luckysheet-uploadData-dialog", 
            "addclass": "luckysheet-uploadData-dialog", 
            "title": locale_uploadData.uploadTextTitle, 
            "content": content, 
            "botton": '<button id="luckysheet-uploadData-dialog-confirm" class="btn btn-primary">'+ locale_button.confirm +'</button><button class="btn btn-default luckysheet-model-close-btn">'+ locale_button.cancel +'</button>', 
            "style": "z-index:100003" 
        }));
        let $t = $("#luckysheet-uploadData-dialog").find(".luckysheet-modal-dialog-content").css("min-width", 400).end(), 
            myh = $t.outerHeight(), 
            myw = $t.outerWidth();
        let winw = $(window).width(), winh = $(window).height();
        let scrollLeft = $(document).scrollLeft(), scrollTop = $(document).scrollTop();
        $("#luckysheet-uploadData-dialog").css({ "left": (winw + scrollLeft - myw) / 2, "top": (winh + scrollTop - myh) / 3 }).show();

        let dataArr = _this.getDataArr();
        _this.dataPreview(dataArr);
        // _this.dataCloudPreview(dataArr);
    },
    init: function(){
        let _this = this;
        const _locale = locale();
        const locale_uploadData = _locale.splitText;

        //数据预览
        // $(document).off("change.SPCinpcheckbox").on("change.SPCcheckbox", "#luckysheet-uploadData-dialog .box input[type='checkbox']", function(){
        //     let dataArr = _this.getDataArr(regStr);
        //     _this.dataPreview(dataArr);
        //     _this.dataCloudPreview(dataArr);
        // });                                                      #luckysheet-uploadData-dialog .box .data-verification-value1
        $(document).off("keyup.SPCinptext").on("keyup.SPCinptext", "#luckysheet-uploadData-dialog .box .data-verification-value1", function(){
            let tableName = _this.getTableName();
            // console.log('tableName', tableName)
            json = {
                "tablename": tableName,
                "password": "hz123456"
            }
            data = JSON.stringify(json)
            $.ajax({
                type: 'post',
                url: setting_luckysheetdata_tablename_url,
                contentType: "application/json",
                data: data,
                success: _this.dataCloudPreview,
                error: function(e) {
                    // alert("提交失败，错误信息：" + e);
                    // $('#submit_buttton').prop('disabled', false);
                }
            })
        })

        //确定按钮
        $(document).off("click.SPCconfirm").on("click.SPCconfirm", "#luckysheet-uploadData-dialog #luckysheet-uploadData-dialog-confirm", function(){
            const _locale = locale();
            const locale_uploadData = _locale.uploadData;
            $("#luckysheet-modal-dialog-mask").hide();
            $("#luckysheet-uploadData-dialog").hide();
            let model = 'append'
            $("#luckysheet-uploadData-dialog .box input[type='checkbox']:checked").each(function(i, e){
                let $id = $(e).attr("id");
                if($id == "uploadColumn_type_06"){ 
                    model = 'replace'
                }
            })
            // console.log(11111111111, )
            // let flag = true
            // let func1 = function(){
            //     flag = true
            // } 

            // if (($("#luckysheet-uploadData-dialog #uploadCloudColumnData").text().indexOf('不匹配') != -1) & (model == 'append')) {
            //     flage = false
            //     tooltip.confirm("", locale_uploadData.uploadConfirmToExe, func1);
            // }
            // console.log($("#luckysheet-uploadData-dialog #uploadCloudColumnData").text().indexOf('不匹配') != -1, model == 'append', 2222222, flag)

            let tableName = _this.getTableName();
            let dataArr = _this.getDataArr();

            // console.log('dataArr', dataArr.slice(0,1), dataArr.slice(1))
            json = {
                "tablename": tableName,
                "password": "hz123456",
                "columns": dataArr[0],
                "data": dataArr.slice(1),
                "model": model
            }
            data = JSON.stringify(json)

            
            $.ajax({
                type: 'post',
                url: setting_luckysheetdata_write_url,
                contentType: "application/json",
                data: data,
                // success: tooltip.confirm("", locale_uploadData.uploadConfirmToExe),
                success: function(res) {
                    tooltip.confirm("", res.data)
                },
                error: function(e) {
                    // alert("提交失败，错误信息：" + e);
                    // $('#submit_buttton').prop('disabled', false);
                }
            })
            
            // tooltip.confirm("", locale_uploadData.uploadConfirmToExe, func1)
        });
    },
    dataPreview: function(dataArr){
        $("#luckysheet-uploadData-dialog #splitColumnData").empty();
        let trHtml = '';

        for(let i = 0; i < dataArr.length; i++){
            let tdHtml = '';

            for(let j = 0; j < dataArr[0].length; j++){
                tdHtml += '<td>' + dataArr[i][j] + '</td>';
            }

            trHtml += '<tr>' + tdHtml + '</tr>';
        }

        let tableHtml = '<table>' + trHtml + '</table>';

        $("#luckysheet-uploadData-dialog #splitColumnData").append(tableHtml);
    },
    dataCloudPreview: function(result){
        $("#luckysheet-uploadData-dialog #uploadCloudColumnData").empty();
        let trHtml = '';
        let dataArr = result.data
        let targetColumns = [];

        let r = Store.luckysheet_select_save[0].row[0];
        let c1 = Store.luckysheet_select_save[0].column[0];
        let c2 = Store.luckysheet_select_save[0].column[1];
        for(let c = c1; c <= c2; c++){
            let cell = Store.flowdata[r][c];

            let value;
            if(cell != null && cell["m"] != null){
                value = cell["m"];
            }
            else{
                value = getcellvalue(r, c, Store.flowdata);
            }

            if(value == null){
                value = "";
            }

            targetColumns.push(value);
        }
        // let targetColumns = getDataArr();
        for(let i = 0; i < dataArr.length; i++){
            trHtml += '<td>' + dataArr[i] + '</td>';
        }
        let tableHtml = ''
        if (dataArr == "None"){
            tableHtml = '<table>' +
            '<tr>' +  
            trHtml +
            '</tr>' +
            '<tr>' +
            '目标数据库不存在' +
            '</tr>' + 
            '</table>';
        } else {
            flag = true
            if (targetColumns.length !== dataArr.length) {
                    flag = false;
              }
            else {
                targetColumns = targetColumns.sort()
                dataArr = dataArr.sort()
                for (var i = 0; i < targetColumns.length; i++) {
                  if (!targetColumns[i] == dataArr[i])
                    flag = false;
              }
            }
            tableHtml = '<table>' +
                            '<tr>' +  
                            trHtml +
                            '</tr>' +
                            '<tr>' +
                            '目标数据库存在' +
                            '</tr>' + 
                            '<tr>' +
                            `与将要录入数据库列名${flag? '':'不'}匹配` +
                            '</tr>' + 
                        '</table>'
        }
        
        

        $("#luckysheet-uploadData-dialog #uploadCloudColumnData").append(tableHtml);
    },
    getTableName: function(){
        let tableName = $("#luckysheet-uploadData-dialog .box .data-verification-value1").val()
        return tableName;
    },
    getDataArr: function(){
        let _this = this;

        let arr = [];

        let r1 = Store.luckysheet_select_save[0].row[0];
        let r2 = Store.luckysheet_select_save[0].row[1];
        let c1 = Store.luckysheet_select_save[0].column[0];
        let c2 = Store.luckysheet_select_save[0].column[1];

        for(let r = r1; r <= r2; r++){
            let rowArr = [];
            for(let c = c1; c <= c2; c++){
                let cell = Store.flowdata[r][c];

                let value;
                if(cell != null && cell["m"] != null){
                    value = cell["m"];
                }
                else{
                    value = getcellvalue(r, c, Store.flowdata);
                }

                if(value == null){
                    value = "";
                }

                rowArr.push(value);
            }
            arr.push(rowArr);
        }
        
        return arr;
    },
    getNullData: function(rlen, clen){
        let arr = [];

        for(let r = 0; r < rlen; r++){
            let rowArr = [];

            for(let c = 0; c < clen; c++){
                rowArr.push("");
            }

            arr.push(rowArr);
        }

        return arr;
    }
}

export default luckysheetUploadData;